import React from 'react';
import type { FC } from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';
import { Layout } from '../components/layout';
// eslint-disable-next-line import/no-unresolved
import { LiverTumorPageQuery } from 'graphql-types';
import { SEO } from '../components/seo';

interface PageProps {
  data: LiverTumorPageQuery;
}

const Page: FC<PageProps> = ({ data }) => (
  <Layout>
    <SEO
      title="肝細胞癌の犬の１例"
      description="症例報告。肝細胞癌の犬の１例"
      keywords="肝細胞癌の犬の１例"
    />
    <article css={caseReportBaseStyle}>
      <h2>犬の肝細胞癌の１例</h2>
      <address>動物がんクリニック東京&nbsp;&nbsp;池田雄太</address>
      <h3>はじめに</h3>
      <p>
        犬の肝臓腫瘍には様々なタイプが発生するが、その多くは肝細胞癌であり約70％を占める。肝細胞癌は進行が比較的緩やかであり、無症状のことも多いため健康診断などで偶発的に発見されることが多い。進行すると肝臓全体に浸潤する場合や、肝機能低下、嘔吐食欲不振などの症状を起こす。今回ｍix犬の肝臓外側左葉に発生した肝細胞癌に対し、肝葉切除を実施し良好に経過している症例を報告する。
      </p>
      <h3>症例</h3>
      <p>
        Mix犬　去勢オス　１２歳
        他院にて肝臓腫瘤が確認され、その精査治療を目的に紹介受診された
        既往歴：特になし
      </p>
      <p>
        体重9.5ｋｇ　体温38.5℃　心拍数1４０回/分　呼吸数30回/分
        一般状態　　　：良好 一般身体検査　：特記すべき異常所見なし
        レントゲン検査：肝臓領域に腫瘤陰影あり
        エコー検査およびＣＴ検査：下記の画像のように腫瘤は肝臓の外側左葉に発生し、大きさは最大径１５cmであった。（図１）大型だが孤立性であり、転移所見も認められなかった。巨大な腫瘤により門脈および後大静脈は右側に圧排され、胃は尾側に移動していた。
        血液検査　　　：軽度の貧血（PCV30％）と肝パネル上昇
      </p>
      <h3>診断</h3>
      <p>肝臓腫瘍うたがい（Ｔ１Ｎ０Ｍ０）</p>
      <h3>治療</h3>
      <p>
        外側左葉の肝葉切除を実施した。手術アプローチは腹部正中切開に両側の傍肋骨切開を併用した。肝門部の血管処理には3-0吸収糸での結紮とチタンクリップを使用した。麻酔からの覚醒は良好であり術後の回復は良好であったため、手術から3日後に退院した。
      </p>
      <h3>病理診断</h3>
      <p>肝細胞癌（高分化型）</p>
      <h3>考察</h3>
      <p>
        犬の肝細胞癌は、ある報告では無治療の場合生存期間は約1年、手術で完全切除した場合は4年以上と報告されている。また腫瘍のサイズよりも個数が重要であり、巨大であっても孤立性で完全切除できれば予後が良好である。本症例のように腫瘍が巨大な場合はその発生部位、大血管との位置関係を把握するためにＣＴ撮影が必須である。肝臓手術の最大の合併症は大出血であり、大血管と近接する腫瘍を摘出する場合などは事前に輸血の準備も必要である。本症例は15㎝という巨大な腫瘍であり通常の正中切開アプローチでは対応できないため、左右両側に傍肋骨切開を実施することで良好な視野が確保され安全に摘出が可能であった。また今回は術中の肝臓からの出血が少なかったため血流遮断は実施しなかったが、緊急事態に備えてプリングル法などのタニケットを設置することは重要である。
        以上のように肝細胞癌の治療は外科治療が第一選択であるが、肝臓・血管・間膜の解剖を把握することが大切であり、入念な準備、手術計画を立てることが大切であると思われる。
      </p>
    </article>
  </Layout>
);
export default Page;

const caseReportBaseStyle = css`
  font-size: 1.3rem;
  line-height: 2rem;
  width: 40em;
  margin: 0 auto;

  address {
    text-align: right;
  }
`;

export const query = graphql`
  query LiverTumorPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
